export const chainIds = {
  ethereum: "1",
  goerli: "5",
  optimism: "10",
  bsc: "56",
  bsctest: "97",
  polygon: "137",
  zksynctest: "280",
  zkSync: "324",
  optimismtest: "420",
  fantomtest: "4002",
  arbitrum: "42161",
  mumbai: "80001",
  fantom: "250",
  arbitrumtest: "421613"
};
